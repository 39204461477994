import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
var DecisionComparisonItem = function (_a) {
    var _b, _c, _d, _e;
    var bucketComparison = _a.bucketComparison;
    function showValues(values, version) {
        return values.map(function (value) { return (<Flex sx={{ justifyContent: 'space-between' }}>
        <Flex sx={{
            marginRight: '3px',
            marginBottom: '3px',
            borderRadius: '.2rem',
            backgroundColor: '#FFFFFF',
            border: '2px',
            borderColor: version === 'new' ? '#cff7fc' : '#dfe3e6',
            gap: '3px',
            padding: '3px',
        }}>
          {value.type}
        </Flex>
        <Flex sx={{
            marginRight: '3px',
            marginBottom: '3px',
            backgroundColor: '#455A64',
            borderRadius: '.2rem',
            color: '#FFFFFF',
            padding: '3px',
        }}>
          {value.values.toString()}
        </Flex>
      </Flex>); });
    }
    return (<>
      <Flex sx={{
        flexDirection: 'column',
        justifyContent: 'top',
        padding: '5px',
    }}>
        <Box sx={{
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
        column: '1',
        fontWeight: 'bold',
        justifyContent: 'column',
    }}>
          {bucketComparison.name}
        </Box>

        <Flex sx={{
        flexDirection: 'raw',
        padding: '5px',
        justifyContent: 'top',
    }}>
          <Flex sx={{
        padding: '5px',
        gap: '5px',
        borderRadius: '.8rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#E9EEF1',
        marginRight: '5px',
        border: '2px',
        borderColor: '#dfe3e6',
    }}>
            <Box data-testid="product-comparison-original-text-type" sx={{
        backgroundColor: '#E9EEF1',
        textAlign: 'center',
        column: '2',
        fontSize: 'sm',
    }}>
              {bucketComparison.originalDecision.type}
            </Box>
            <Box data-testid="product-comparison-original-text-values" sx={{
        textAlign: 'center',
        whiteSpace: 'pre-line',
        fontSize: 'sm',
    }}>
              {showValues((_c = (_b = bucketComparison.originalDecision) === null || _b === void 0 ? void 0 : _b.values) !== null && _c !== void 0 ? _c : [], 'original')}
            </Box>
          </Flex>
          <Flex sx={{
        padding: '5px',
        gap: '5px',
        borderRadius: '.8rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#E0F7FA',
        marginRight: '5px',
        border: '2px',
        borderColor: '#cff7fc',
    }}>
            <Box data-testid="product-comparison-new-text-type" sx={{
        backgroundColor: '#E0F7FA',
        textAlign: 'center',
        column: '2',
        fontSize: 'sm',
    }}>
              {bucketComparison.newDecision.type}
            </Box>
            <Box data-testid="product-comparison-new-text-values" sx={{
        textAlign: 'center',
        whiteSpace: 'pre-line',
        fontSize: 'sm',
    }}>
              {showValues((_e = (_d = bucketComparison.newDecision) === null || _d === void 0 ? void 0 : _d.values) !== null && _e !== void 0 ? _e : [], 'new')}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>);
};
export default DecisionComparisonItem;
