import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DecisionComparisonItem from '@/pages/policy-management/view/[policyReference]/MisRep/DecisionComparison/DecisionComparisonItem';
var DecisionComparisons = function (_a) {
    var bucketComparisons = _a.bucketComparisons;
    var t = useTranslation(['policy-management/summary']).t;
    return (<>
      {bucketComparisons !== undefined && (<Flex sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '2px',
        borderColor: '#E7E7E7',
        borderRight: '0px',
        borderLeft: '0px',
        backgroundColor: 'white',
        paddingLeft: '200px',
        paddingRight: '200px',
    }}>
          <Box data-testid="product-decisions-title" sx={{
        textAlign: 'center',
        column: '1',
        padding: '10px',
        fontWeight: 'bold',
    }}>
            {t('DECISION_COMPARISONS.TITLE')}
          </Box>

          <Flex sx={{
        flexDirection: 'raw',
        justifyContent: 'space-between',
    }}>
            {bucketComparisons.length === 0 &&
        t('DECISION_COMPARISONS.NO_COMPARISON')}
            {bucketComparisons.map(function (bucketComparison) { return (<DecisionComparisonItem bucketComparison={bucketComparison}/>); })}
          </Flex>
        </Flex>)}
    </>);
};
export default DecisionComparisons;
